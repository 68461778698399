<template>
   <div style="width:100%">
		<v-card block :loading="isLoading">
			<v-card-title>
				Управление событиями <v-chip label small class="ml-2">Всего: {{ total }}</v-chip>
				<v-spacer></v-spacer>
				
				<v-badge 
					:overlap="((filterTime || sectionsFilter.length) ? true : false)" 
					:content="(filterTime || sectionsFilter.length) ? (filterTime ? 1 + sectionsFilter.length : sectionsFilter.length) : null" 
					:color="((filterTime || sectionsFilter.length) ? 'orange accent-4':'#fff')" 
					:bordered="((filterTime || sectionsFilter) ? true : false)"
					class="mr-5" 
				>
					<v-btn icon color="blue"  @click="dialogFilter = !dialogFilter">
						<v-icon>mdi-filter</v-icon>
					</v-btn>
				</v-badge>
				<!-- <v-btn color="primary" @click="getDialogNewEvent()" elevation="0">Добавить событие</v-btn> -->
			</v-card-title>
			<v-card-text>
				<v-text-field label="Поиск события" filled v-model="searchEvent" clearable></v-text-field>

				<v-data-table :headers="headers" :items="events" :items-per-page="countRows" class="elevation-1" hide-default-footer>
					<template v-slot:item.section="{ item }">
						<!-- <v-chip 
							outlined 
							color="primary" 
							style="cursor: pointer;"
						>
							@click="toggleSectionFilter({filter: sectionsFilter.includes(item.section) ? false : true, id: item.section}, true)"
							{{ sections.find((i) => (i.id == item.section))?.title }}
						</v-chip> -->
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-btn small elevation="0" v-bind="attrs" v-on="on">{{sections.find((i) => (i.id == item.section))?.title[0]}}</v-btn>
							</template>
							<span>{{sections.find((i) => (i.id == item.section))?.title}}</span>
						</v-tooltip>
					</template>
					
					<template v-slot:item.setting="{ item }">
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-badge v-if="item.count_registrations != '0'" overlap :content="item.count_registrations" left>
									<v-btn small icon @click="openUsersDialog( item.id )" :loading="btnLoadingUsers == item.id" color="primary" class="mr-2" v-bind="attrs" v-on="on">
										<v-icon>mdi-account-multiple-outline</v-icon>
									</v-btn>
								</v-badge>
								<v-btn v-else small icon @click="openUsersDialog( item.id )" :loading="btnLoadingUsers == item.id" color="primary" class="mr-2" v-bind="attrs" v-on="on">
									<v-icon>mdi-account-multiple-outline</v-icon>
								</v-btn>
							</template>
							<span>Управление пользователями</span>
						</v-tooltip>
						
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-btn small icon @click="openEditDialog( item.id )" :loading="btnLoadingEdit == item.id" color="teal" class="mr-2" v-bind="attrs" v-on="on">
									<v-icon>mdi-circle-edit-outline</v-icon>
								</v-btn>
							</template>
							<span>Редактировать событие</span>
						</v-tooltip>
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-btn small icon @click="deleteEvent( item.id )" color="red" v-bind="attrs" v-on="on">
									<v-icon>mdi-delete-outline</v-icon>
								</v-btn>
							</template>
							<span>Удалить событие</span>
						</v-tooltip>
					</template>
					
					<template v-slot:item.started_at="{ item }">
						{{item.started_at.toLocaleString("ru-RU", {hour12: false, year: 'numeric', month: '2-digit', day: '2-digit'})}}
						<!-- {{item.started_at.toLocaleString("ru-RU", {hour12: false, year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit'})}} -->
					</template>
				</v-data-table>
				<div class="pt-4">
					<v-row justify="space-between">
						<v-col cols=2 align-self="center">
							<v-select :items="itemsCountRows" v-model="countRows" label="Кол-во записей" hide-details solo></v-select>
						</v-col>
						<v-spacer></v-spacer>
						<v-col align-self="center" class="pag-end">
							<v-pagination v-model="page" :length="totalPage" :total-visible="7"></v-pagination>
						</v-col>
					</v-row>
				</div>
			</v-card-text>
		</v-card>

		<v-dialog v-model="dialogFilter" max-width="600px">
			<v-card>
				<v-toolbar color="primary" dark>Фильтры</v-toolbar>
				<v-card-text>
					<h2 class="mt-3">Фильтр по времени</h2>
					<v-radio-group v-model="filterTime" mandatory>
						<v-radio v-for="(item, k) in filterTimeValues" :key="k" :value="item.id" :label="item.name" />
					</v-radio-group>
					<v-divider />

					<h2 class="mt-3 mb-3">Фильтр по типу события</h2>
					<div v-for="section, index in sections" :key="index">
						<v-checkbox
							v-model="section.filter"
							:label="section.title"
							class="mt-0"
							hide-details
							@change="toggleSectionFilter(section, false)"
						/>
					</div>

				</v-card-text>
				<v-card-actions>
					<v-btn color="primary darken-1" text @click="dialogFilter = false" >
						Закрыть
					</v-btn>
					<v-spacer></v-spacer>
					<v-btn color="teal darken-1" text @click="getEvents()">
						Применить
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- Редактирование события -->
		<v-dialog v-model="dialogEvent" max-width="1200px">
			<v-card>
				<v-toolbar color="primary" dark>
					<v-toolbar-title>Редактирование события #{{dataEvent.id}}</v-toolbar-title>
				</v-toolbar>
				<v-card-text>
					<div class="mt-5">
						
						<!-- Заголовок события -->
						<v-text-field
							label="Заголовок события"
							auto-grow
							v-model="dataEvent.title"
							class="mb-4"
						/>
						
						<!-- Локальные время и дата начала события -->
						<v-row align="center" class="mb-4">
							<v-col cols="4">
								<v-menu
									v-model="calendar_menu"
									:close-on-content-click="false"
									:nudge-right="40"
									transition="scale-transition"
									offset-y
									min-width="auto"
								>
									<template v-slot:activator="{ on, attrs }">
										<v-text-field
											v-model="calendar_date"
											label="Дата начала события"
											prepend-icon="mdi-calendar"
											readonly
											v-bind="attrs"
											v-on="on"
										></v-text-field>
									</template>
									<v-date-picker
										v-model="calendar_date"
										@input="calendar_menu = false"
										locale="ru"
									></v-date-picker>
								</v-menu>
							</v-col>
							<v-col cols="4">
								<v-menu
									ref="menu"
									v-model="time_menu"
									:close-on-content-click="false"
									:nudge-right="40"
									:return-value.sync="calendar_time"
									transition="scale-transition"
									offset-y
									max-width="290px"
									min-width="290px"
								>
									<template v-slot:activator="{ on, attrs }">
										<v-text-field
											v-model="calendar_time"
											label="Время начала события"
											prepend-icon="mdi-clock-time-four-outline"
											readonly
											v-bind="attrs"
											v-on="on"
										></v-text-field>
									</template>
									<v-time-picker
										v-if="time_menu"
										v-model="calendar_time"
										full-width
										@click:minute="$refs.menu.save(calendar_time)"
										format="24hr"
									></v-time-picker>
								</v-menu>
							</v-col>
							<v-col cols="4">
								<v-text-field
									:value="timezones.find((i) => (i.id == dataEvent.timezone)).title"
									label="Часовой пояс (определяется автоматически)"
									readonly
								></v-text-field>
							</v-col>
						</v-row>

						<!-- Тип события -->
						<v-select
							:items="sections"
							v-model="dataEvent.section"
							item-text="title"
							item-value="id"
							label="Тип события"
							class="mb-4"
							chips
						/>

						<!-- Регионы события -->
						<v-select
							:items="regions"
							v-model="dataEvent.region_ids"
							item-text="title"
							item-value="id"
							label="Регионы события"
							attach
							chips
							multiple
							class="mb-4"
						/>

						<!-- Аудитория события -->
						<v-select
							:items="audiences"
							v-model="dataEvent.audience_ids"
							item-text="title"
							item-value="id"
							label="Аудитория события"
							attach
							chips
							multiple
							class="mb-4"
						/>

						<!-- Анонс -->
						<v-textarea
							outlined
							label="Подробный анонс мероприятия"
							v-model="dataEvent.text"
							class="mb-4"
						/>

						<!-- Формат проведения -->
						<h4>Удалённый формат проведения (вебинар)</h4>
						<v-checkbox
							v-model="dataEvent.web"
							label="Да"
							class="mb-4"
						/>

						<!-- Ссылка на вебинар -->
						<v-text-field
							v-if="dataEvent.web"
							label="Ссылка на вебинар"
							auto-grow
							outlined
							v-model="dataEvent.web_url"
							:messages="[`Ссылка на вебинар на площадке Miropolis VR. После создания мероприятия на площадке, скопируйте содержимое поля 'Код для вставки формы регистрации' в данное поле.`]"
							class="mb-4"
						/>

						<!-- Место проведения -->
						<v-text-field
							v-else
							label="Место проведения"
							auto-grow
							outlined
							v-model="dataEvent.place"
							:messages="[`Адрес обязателен для заполнения если это не вебинар, для вебинаров может отсутствовать.`]"
							class="mb-4"
						/>

						<!-- Включить регистрацию на событие? -->
						<v-select
							:items="registrables"
							v-model="dataEvent.registrable"
							item-text="title"
							item-value="id"
							outlined
							chips
							label="Включить регистрацию на событие?"
						/>

						<!-- Дата завершения регистрации -->
						<v-row align="center" v-if="dataEvent.registrable">
							<v-col cols="4">
								<v-menu
									v-model="calendar_menu_registration"
									:close-on-content-click="false"
									:nudge-right="40"
									transition="scale-transition"
									offset-y
									min-width="auto"
								>
									<template v-slot:activator="{ on, attrs }">
										<v-text-field
											v-model="dataEvent.registration"
											label="Дата завершения регистрации"
											prepend-icon="mdi-calendar"
											readonly
											v-bind="attrs"
											v-on="on"
										></v-text-field>
									</template>
									<v-date-picker
										v-model="dataEvent.registration"
										@input="calendar_menu_registration = false"
										locale="ru"
									></v-date-picker>
								</v-menu>
							</v-col>
						</v-row>
					</div>
				</v-card-text>
				<v-card-actions>
					<v-btn color="primary darken-1" text @click="dialogEvent = false" >
						Закрыть
					</v-btn>
					<v-spacer></v-spacer>
					<v-btn color="teal darken-1" text @click="saveEditEvent(dataEvent.id)">
						Сохранить
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- Пользователи, зарегистрировавшиеся на событие -->
		<v-dialog v-model="dialogEventUsers" max-width="1200px">
			<v-card>
				<v-toolbar color="primary" dark>
					<v-toolbar-title>Пользователи, зарегистрировавшиеся на событие #{{tmpEventId}}</v-toolbar-title>
					<v-spacer></v-spacer>

					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-btn icon v-bind="attrs" v-on="on" @click="openDialogAddRegistrations(tmpEventId)">
								<v-icon>mdi-account-plus</v-icon>
							</v-btn>
						</template>
						<span>Зарегистрировать пользователя</span>
					</v-tooltip>
				</v-toolbar>
				<v-card-text>
					<v-data-table
						:headers="eventUsersHeader"
						:items="dataEventUsers"
						item-key="id"
						:footer-props="{
							'items-per-page-text':'показать на странице:'
						}"
						:search="searchEventUsers"
					>
						<template v-slot:top>
							<v-text-field
								v-model="searchEventUsers"
								label="Поиск"
								class="mx-5"
							></v-text-field>
						</template>
						
						<template v-slot:item.setting="{ item }">
							<!-- <v-tooltip bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-btn small icon @click="openDialogEditRegistration(item.id)" color="teal" class="mr-2" v-bind="attrs" v-on="on">
										<v-icon>mdi-circle-edit-outline</v-icon>
									</v-btn>
								</template>
								<span>Редактировать</span>
							</v-tooltip> -->
							<v-tooltip bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-btn small icon @click="deleteEventRegistration( item.event_id, item.id )" color="red" v-bind="attrs" v-on="on">
										<v-icon>mdi-delete-outline</v-icon>
									</v-btn>
								</template>
								<span>Удалить регистрацию</span>
							</v-tooltip>
						</template>
						
						<template slot="no-data">
							Регистрации не найдены, добавьте пользователей
						</template>
						
						<template v-slot:item.created_at="{ item }">
							<!-- {{item.created_at.toLocaleString("ru-RU", {hour12: false, year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit'})}} -->
							{{item.created_at.toLocaleString("ru-RU", {hour12: false, year: 'numeric', month: '2-digit', day: '2-digit'})}}
						</template>
						
						<template v-slot:item.fio="{ item }">
							{{item.first_name}} {{item.middle_name}} {{item.last_name}}
						</template>

						<template v-slot:item.audiences_title="{ item }">
							<v-tooltip bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-btn small elevation="0" v-bind="attrs" v-on="on">{{item.audiences_title[0]}}</v-btn>
									<!-- <v-btn small elevation="0" v-bind="attrs" v-on="on">{{item.audiences_title.slice(0, 6)}}...</v-btn> -->
								</template>
								<span>{{item.audiences_title}}</span>
							</v-tooltip>
						</template>

						<template v-slot:item.vuz="{ item }">
							<v-tooltip bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-btn 
										v-if="item.school_abbr || item.school_short_name" 
										small elevation="0" color="indigo lighten-5" v-bind="attrs" v-on="on"
										:to="{
											name: 'Organizations',
											query: {
												search: item.school_id,
											}
										}"
									>
										<!-- #{{item.school_id}} -->
										<!-- {{item.school_abbr ? item.school_abbr : item.school_short_name}} -->
										<v-icon>mdi-school</v-icon>
									</v-btn>
								</template>
								<span>{{item.school_abbr ? item.school_abbr : item.school_short_name}}</span>
							</v-tooltip>
						</template>

						<template v-slot:item.rumc="{ item }">
							<!-- {{item.rumc_title_short}} -->
							<v-tooltip bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-btn 
										v-if="item.rumc_title_short" 
										small elevation="0" color="indigo lighten-5" v-bind="attrs" v-on="on"
										:to="{
											name: 'Rumcs',
											query: {
												search: item.rumc_id,
											}
										}"
									>
										<v-icon>mdi-human-wheelchair</v-icon>
									</v-btn>
								</template>
								<span>{{item.rumc_title_short}}</span>
							</v-tooltip>
						</template>

						<!-- <template v-slot:item.audiences_title="{ item }">
							<div class="running_line_container">
								<div class="running_line_text">
									{{item.audiences_title}}
								</div>
							</div>
						</template> -->

					</v-data-table>
				</v-card-text>
				<v-card-actions>
					<v-btn color="primary darken-1" text @click="dialogEventUsers = false" >
						Закрыть
					</v-btn>
					<v-spacer></v-spacer>
					<v-btn color="teal darken-1" text @click="registrationsExportExcel(tmpEventId)" :loading="loadingExcelRegistrations">
						Экспорт в Excel
						<v-icon right dark>mdi-file-excel </v-icon>
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>


		<!-- Регистрация / редактирование регистрации -->
		<v-dialog v-model="dialogAddRegistration" max-width="800px">
			<v-card>
				<v-toolbar color="primary" dark>
					<v-toolbar-title v-if="!registrationEditMode">Зарегистрировать пользователя</v-toolbar-title>
					<v-toolbar-title v-if="registrationEditMode">Редактировать регистрацию</v-toolbar-title>
				</v-toolbar>
				<v-card-text>
					<v-form
						ref="registrationForm"
						v-model="validAddRegistrationForm"
						lazy-validation
					>
						<v-text-field
							label="Имя*"
							auto-grow
							v-model="addRegistrationData.first_name"
							class="mb-4"
							required
							:rules="addRegistrationRules"
						/>
						<v-text-field
							label="Фамилия*"
							auto-grow
							v-model="addRegistrationData.last_name"
							class="mb-4"
							required
							:rules="addRegistrationRules"
						/>
						<v-text-field
							label="Отчество"
							auto-grow
							v-model="addRegistrationData.middle_name"
							class="mb-4"
						/>

						<!-- Регион участника -->
						<v-select
							:items="regions"
							v-model="addRegistrationData.region_id"
							item-text="title"
							item-value="id"
							label="Регион участника*"
							attach
							chips
							class="mb-4"
							required
							:rules="addRegistrationRules"
						>
							<!-- <template v-slot:prepend-item>
								<v-list-item>
									<v-list-item-content>
									<v-text-field v-model="searchTerm" placeholder="Search" @input="searchFruits"></v-text-field>
									</v-list-item-content>
								</v-list-item>
								<v-divider class="mt-2"></v-divider>
							</template> 
							https://codepen.io/sudheer-ranga/pen/bGVbjbx
							-->
						</v-select>

						<!-- Категория участника -->
						<v-select
							:items="audiences"
							v-model="addRegistrationData.audience_id"
							item-text="title"
							item-value="id"
							label="Категория участника*"
							attach
							chips
							class="mb-4"
							required
							:rules="addRegistrationRules"
						/>

						<!-- ВУЗ, если категория: 
							Студент; 
							Научно-педагогический персонал вуза; 
							Административно-управленческий персонал вуза; 
							Учебно-вспомогательный персонала вуза
						-->
						<v-select
							v-if="['8', '3', '6', '7'].includes(addRegistrationData.audience_id)"
							:items="schools"
							v-model="addRegistrationData.school_id"
							item-text="title"
							item-value="id"
							label="Вуз*"
							attach
							chips
							class="mb-4"
						>
							<template v-slot:prepend-item>
								<v-list-item>
									<v-list-item-content>
										<v-text-field v-model="searchSchool" placeholder="Введите часть названия вуза" @input="searchSchools()"></v-text-field>
									</v-list-item-content>
								</v-list-item>
								<v-divider class="mt-2"></v-divider>
							</template> 
							
						</v-select>

						<!-- РУМЦ, если категория: Сотрудник РУМЦ -->
						<v-select
							v-if="addRegistrationData.audience_id == '5'"
							:items="rumcs"
							v-model="addRegistrationData.rumc_id"
							item-text="title"
							item-value="id"
							label="Ресурсный центр*"
							attach
							chips
							class="mb-4"
						/>
					</v-form>
					<small>*поля обязательны для заполнения</small>
				</v-card-text>
				<v-card-actions>
					<v-btn color="primary darken-1" text @click="closeDialogAddRegistrations() " >
						Закрыть
					</v-btn>
					<v-spacer></v-spacer>
					<v-btn color="teal darken-1" text @click="addRegistration()">
						Сохранить
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

   </div>
</template>

<script>
export default {
	// ToDo: ссылки на СМИ
	data () {
		return {
			isLoading : false,

			headers: [
				{ text: 'ID', align: 'start', sortable: true, value: 'id', },
				{ text: 'Наименование', value: 'title' },
				{ text: 'Дата начала', value: 'started_at', align: 'center' },
				{ text: 'Тип события', value: 'section', sortable: false, align: 'center' },
				{ text: 'Автор', value: 'author', sortable: false, width:'150px' },
				{ text: 'Управление', value: 'setting', align: 'right', width:'150px', sortable: false },
			],

			itemsCountRows : [
				12, 20, 50, 100, 500
			],

			events: [],
			rumcs: [],
			regions: [],
			audiences: [],
			schools: [],

			total: 0,
			page: 1,
			totalPage: 1,
			countRows: 12,

			dialogEvent: false,
			createMode: false,
            editMode: false,
			btnLoadingEdit: 0,
			btnLoadingUsers: 0,
			dataEvent: {
				id: 0,
				title: '',
				started_at: null,
				timezone: new Date().getTimezoneOffset() / (-60),
				text: "",
				section: null,
				region_ids: [],
				audience_ids: [],
				web: false,
				web_url: null,
				place: "",
				registration: '',
				registrable: 0,
			},
			calendar_date: '',
			calendar_time: '',
			calendar_menu: false,
			calendar_menu_registration: false,
			time_menu: false,
			sections: [
				{id: 1, alt: "webinar", title: "Вебинар", filter: false},
				{id: 2, alt: "training", title: "Семинар", filter: false},
				{id: 3, alt: "profession", title: "Профориентация", filter: false},
				{id: 4, alt: "employment", title: "Трудоустройство", filter: false},
				{id: 5, alt: "development", title: "Мероприятие", filter: false},
				{id: 6, alt: "conference", title: "Совещание с РУМЦ", filter: false},
				{id: 7, alt: "meeting", title: "Совещание с вузами", filter: false},
				{id: 8, alt: "volunteering", title: "Волонтёрство", filter: false},
				{id: 9, alt: "young", title: "Сопровождение молодых инвалидов", filter: false},
				{id: 10, alt: "events_r_p", title: "Мероприятия по региональным программам", filter: false},
				{id: 99, alt: "other", title: "Другое", filter: false}
			],
			// UTC + id
			timezones: [
				{id: 2, title: 'Калининград'},
				{id: 3, title: 'Москва'},
				{id: 4, title: 'Самара'},
				{id: 5, title: 'Екатеринбург'},
				{id: 6, title: 'Омск'},
				{id: 7, title: 'Красноярск'},
				{id: 8, title: 'Иркутск'},
				{id: 9, title: 'Якутск'},
				{id: 10, title: 'Владивосток'},
				{id: 11, title: 'Магадан'},
				{id: 12, title: 'Камчатка'},
			],
			registrables: [
				{id: 0, title: 'Нет'},
				{id: 1, title: 'Все пользователи'},
				{id: 2, title: 'Сотрудники ресурсных центров'},
			],

			dialogFilter: false,
			searchEvent: '',
			filterTime: null,
			filterTimeValues: [
				{id: 0, name: 'Все события'}, 
				{id: 1, name: 'Предстоящие события'}, 
				{id: 2, name: 'Архив событий'}
			],
			sectionsFilter: [],

			dialogEventUsers: false,
			tmpEventId: 0,
			dataEventUsers: [],
			searchEventUsers: '',
			eventUsersHeader: [
				// { text: 'ФИО', align: 'start', sortable: true, value: 'fio' },
				{ text: 'Фамилия', align: 'start', sortable: true, value: 'last_name' },
				{ text: 'Имя', align: 'center', sortable: true, value: 'first_name' },
				{ text: 'Отчество', align: 'center', sortable: true, value: 'middle_name' },

				{ text: 'Дата регистрации', align: 'center', sortable: false, value: 'created_at' },
				{ text: 'ВУЗ', align: 'center', sortable: false, value: 'vuz' },
				{ text: 'РУМЦ', align: 'center', sortable: false, value: 'rumc' },
				{ text: 'Категория', align: 'center', sortable: true, value: 'audiences_title', width: '170px' },
				{ text: 'Регион', align: 'center', sortable: true, value: 'regions_title' },
				{ text: 'Управление', value: 'setting', align: 'center', sortable: false },
			],
			dialogAddRegistration: false,
			addRegistrationData: {},
			validAddRegistrationForm: true,
			addRegistrationRules: [ v => !!v || 'Поле обязательно для заполнения!' ],
			registrationEditMode: false,
			registrationEditID: 0,
			searchSchool: '',
			loadingExcelRegistrations: false,
		}
	},
	watch: {
		'page': function(){
			if ( !this.isLoading )
				this.getEvents()
		},
		'countRows': function(val) {
			if ( !this.isLoading ) {
				this.getEvents()
				localStorage.setItem("countRows", parseInt(val))
			}
		},
		'searchEvent': function() {
			this.getEvents()
		},
		'dialogAddRegistration': function() {
			if (this.dialogAddRegistration == false) {
				this.registrationEditMode = false;
				this.registrationEditID = 0;
				this.addRegistrationData = {};
				this.$refs.registrationForm.resetValidation();
			}
		}
	},
	methods: {
		toggleSectionFilter(section, apply = false) {
			if (section.filter) {
				this.sectionsFilter.push(section.id)
			} else {
				this.sectionsFilter = this.sectionsFilter.filter((i) => (i != section.id))
			}
			if (apply) { this.getEvents() }
		},

		saveEditEvent( id ){
			// this.$toast.warning(`Не реализовано`);
            this.$confirm.q(`Сохранить изменения события?`, () => {
				this.$http.put(`events/${id}`, {form: this.dataEvent}).then((response) => {
					if ( response.data.status ) {
						this.dialogEvent = false
						this.getEvents()
					} else {
						this.$toast.error(`Возникли ошибки при сохранении изменений`);
					}
				});
			}, () => {
				this.$toast.warning("Действие отменено пользователем");
			})
		},

		// Управление зарегистрировавшимися на события пользователями
		openUsersDialog ( id ) {
			this.tmpEventId = id;
			this.btnLoadingUsers = id;
			this.dialogEventUsers = true;
			this.getEventRegistrations(id);
		},

		openDialogAddRegistrations(id) {
			var event = this.events.find((i) => (i.id == id))
			var started_at = new Date(event.started_at)
			var now = new Date();
			if (started_at < now) {
				this.$toast.warning("Событие завершено!");
				return
			}

			this.dialogAddRegistration = true;
		},

		closeDialogAddRegistrations () {
			this.dialogAddRegistration = false;
			this.$refs.registrationForm.resetValidation();
		},

		getEventRegistrations (id) {
			this.$http.get(`events/${id}/registrations`).then((response) => {
				if ( response.data.status ) {
					this.dataEventUsers = response.data.item;
					this.dataEventUsers.forEach((i) => (i.created_at = new Date(i.created_at)))

					setTimeout(() => {
						this.btnLoadingUsers = false;
					},300)
				} else {
					this.$toast.error(`Ошибка`);
				}
			});
		},

		deleteEventRegistration ( id, event_registration_id ) {
			this.$confirm.q(`Аннулировать регистрацию пользователя?`, () => {
				this.$http.delete(`events/registrations/${event_registration_id}`).then((response) => {
					if ( response.data.status ) {
						this.$toast.open('Регистрация аннулирована');
						this.getEventRegistrations(id);
					} else {
						this.$toast.error(`Ошибка`);
					}
				});
			}, () => {
				this.$toast.warning("Действие отменено пользователем");
			})
		},

		searchSchools () {
			if (this.searchSchool.length >= 3) {
				this.$http.get("organizations", { params: {
					count: 50,
					page: 1,
					search: this.searchSchool
				} }).then((response) => {
					this.schools = response.data.items
				});
			}
		},

		registrationsExportExcel( id ) {
			this.loadingExcelRegistrations = true;
			this.$http({
				method: 'post',
				url: `events/${id}/registrations/export`, 
				body: {form: this.addRegistrationData},
				responseType: 'blob'
			}).then((response) => {
				if ( response.data ) {
					this.$toast.open('Документ сформирован!');
					this.loadingExcelRegistrations = false;
					
					let blob = new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})
					let url = window.URL.createObjectURL(blob);
					let link = document.createElement('a');
					link.href = url;
					link.download = `Регистрации_${id}.xlsx`
					document.body.appendChild(link);
					link.click();
					link.remove();
				} else {
					this.$toast.error(`Ошибка`);
				}
			});
		},

		openDialogEditRegistration( id ) {
			this.addRegistrationData = this.dataEventUsers.find((i) => i.id == id);
			this.addRegistrationData.region_id = String(this.addRegistrationData.region_id);
			this.addRegistrationData.rumc_id = String(this.addRegistrationData.rumc_id);

			this.dialogAddRegistration = !this.dialogAddRegistration; 
			this.registrationEditMode = true;
			this.registrationEditID = id;
		},

		addRegistration() {
			if (!this.registrationEditMode) {
				if (this.$refs.registrationForm.validate()) {
					this.$confirm.q(`Зарегистрировать пользователя?`, () => {
						this.$http.post(`events/${this.tmpEventId}/registrations`, {form: this.addRegistrationData}).then((response) => {
							if ( response.data.status ) {
								this.$toast.open('Пользователь успешно зарегистрирован!');
								this.getEventRegistrations(this.tmpEventId);
								this.dialogAddRegistration = false
							} else {
								this.$toast.error(`Ошибка`);
							}
						});
					}, () => {
						this.$toast.warning("Действие отменено пользователем");
					})
				}
			} else {
				if (this.$refs.registrationForm.validate()) {
					this.$confirm.q(`Обновить регистрацию?`, () => {
						this.$http.put(`events/registrations/${this.registrationEditID}`, {form: this.addRegistrationData}).then((response) => {
							if ( response.data.status ) {
								this.$toast.open('Регистрация успешно обновлена!');
								this.getEventRegistrations(this.tmpEventId);
								this.dialogAddRegistration = false;
								this.registrationEditMode = false;
								this.registrationEditID = 0;
							} else {
								this.$toast.error(`Ошибка`);
							}
						});
					}, () => {
						this.$toast.warning("Действие отменено пользователем");
					})
				}
			}
		},

		// ? Получить информацию о событии для редактирования 
		openEditDialog( id ) {
			this.btnLoadingEdit = id
			this.$http.get(`events/${id}`).then((response) => {
				if ( response.data.status ) {
					this.dialogEvent = true
					this.dataEvent = response.data.item
					if (response.data.item.registration) {
						this.dataEvent.registration = new Date(response.data.item.registration).toISOString().substr(0, 10)
					}
					var dateTime_started = new Date(response.data.item.started_at)
					dateTime_started.setHours(dateTime_started.getHours() + response.data.item.timezone + 3)
					dateTime_started = dateTime_started.toISOString()
					this.calendar_date = dateTime_started.substr(0, 10)
					this.calendar_time = dateTime_started.substr(11, 5)

					setTimeout(() => {
						this.btnLoadingEdit = false
					},300)
				} else {
					this.$toast.error(`Возникли ошибки при сохранении новости`);
				}
			});
		},

		// ? Удалить событие
		deleteEvent( id ) {
			this.$toast.warning(`Событие ${id} не может быть удалено. Действие заблокировано!`);
		},

		getEvents() {
			this.dialogFilter = false;
			this.isLoading = true;
			this.$http.get("events", { params: {
				count: this.countRows,
				page: this.page,
				search: this.searchEvent,
				filterTime: this.filterTime,
				filterSection: this.sectionsFilter.join("_")
			} }).then((response) => {
				this.events = response.data.items
				// this.events.forEach((i) => (i.started_at = new Date(i.started_at)))
				this.events.forEach((i) => {
					var tmp = new Date(i.started_at)
					tmp.setHours(tmp.getHours() + 3)
					i.started_at = tmp;
				})

				

				this.total = parseInt(response.data.options.total)
				this.page = parseInt(response.data.options.page)
				this.totalPage = parseInt(response.data.options.totalPage)

				this.regions = response.data.regions
				this.rumcs = response.data.rumcs.rumcsList
				this.audiences = response.data.audiences

				setTimeout(() => {
					this.isLoading = false
				}, 200)
			});
		}
	},
	created(){
		const countRows = localStorage.getItem("countRows")
		if ( countRows && parseInt(countRows) > 0 ) {
			this.countRows = parseInt(countRows)
		}
		this.getEvents()
	}
}
</script>

<style>
.link-email { color:#1A237E; text-decoration: none !important; border-bottom: 1px dashed #1A237E;}
.pag-end .v-pagination { justify-content: right !important; }

.running_line_container {
	padding: 5px 8px;
	border: 1px solid #1976d2;
	border-radius: 5px;
	position: relative;
}
.running_line_text {
	position: absolute;
	white-space: nowrap;
	transition: 2s;
}
</style>